import {
  DeeplinkBreakdown,
  DohopService,
  OfferResponse,
  OrderSummaryResponseBookingsItem,
  PollResponseBookingsItem,
  ServiceBooking,
  TierName,
} from '@codegen/offerAPI';
import { Booking } from '@utils/orderTypes';
import { parseQueryString, parseQueryStringToObject } from '@utils/queryUtils';
import { getTierNameFromId } from '@utils/tieredConnectionServiceUtils';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import { useServiceConfig } from '@web/context/hooks/useServiceConfig';
import { getTierIdFromDeeplink } from '@web/utils/booking/deeplinkPriceUtils';

export const getSelectedTierInfo = (
  data:
    | OfferResponse
    | OrderSummaryResponseBookingsItem[]
    | PollResponseBookingsItem[]
    | Booking[]
    | DeeplinkBreakdown
    | string
    | undefined,
  hasMultipleTiers: boolean,
  query: { [key: string]: string | string[] | undefined },
) => {
  if (!data) {
    return { selectedTierId: undefined, selectedTierPrice: undefined };
  } else if (typeof data === 'string') {
    const { fares, home, out } = parseQueryStringToObject(data);

    const selectedTierId = getTierIdFromDeeplink({
      fares,
      out,
      home,
      hasMultipleTiers,
    });

    // This is deeplinks where we don't need the fee
    return { selectedTierId, selectedTierPrice: undefined };
  } else if ('offer_id' in data) {
    const selectedTier = data.all_pax_services.find(
      (service): service is DohopService =>
        'tier_id' in service && service.quantity === 1,
    );

    return {
      selectedTierId: selectedTier?.tier_id as string,
      selectedTierPrice: selectedTier?.price.amount,
    };
  } else if ('breakdown' in data) {
    const { fares, home, out } = query;

    const selectedTierId = getTierIdFromDeeplink({
      fares,
      out,
      home,
      hasMultipleTiers,
    });

    return {
      selectedTierId,
      selectedTierPrice: data.breakdown.find(
        (service) => service.booking_type === 'service',
      )?.price.amount,
    };
  }

  const selectedTier = data.find(
    (service): service is ServiceBooking => 'tier_id' in service,
  );

  return {
    selectedTierId: selectedTier?.tier_id as TierName,
    selectedTierPrice: selectedTier?.total.amount,
  };
};

// We can get information about the tiers from the offer, order summary,  poll response or the deeplink either from the booking page initial load or the search page offer transfer link
const useTiers = () => {
  const {
    query: { is_tiered, ...rest },
  } = usePartnerRouter();

  const { baseTierName, commissionTierName, premiumTierId, premiumTierName } =
    useServiceConfig();

  const isTieredDeeplink = Boolean(parseQueryString(is_tiered) === 'True');

  const getTierName = (tierId: TierName) =>
    getTierNameFromId({
      baseTierName,
      premiumTierName,
      commissionTierName,
      tierId,
    });

  const hasMultipleTiers = Boolean(baseTierName && premiumTierName);

  const getSelectedTier = (
    parameters: Parameters<typeof getSelectedTierInfo>[0],
  ) => {
    const { selectedTierId, selectedTierPrice } = getSelectedTierInfo(
      parameters,
      hasMultipleTiers,
      rest,
    );

    return {
      selectedTierId: selectedTierId as TierName,
      selectedTierPrice,
      hasSelectedPremiumTier: selectedTierId === premiumTierId,
      selectedTierName: getTierName(selectedTierId as TierName),
    };
  };

  return {
    isTieredConnectionService: isTieredDeeplink && hasMultipleTiers,
    getTierName,
    hasMultipleTiers,
    getSelectedTier,
  };
};

export default useTiers;
